
@import "~@library/theme";


.wrapper {

  position: relative;
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  max-width: 380px;
  align-items: center;
  background: $primary;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba($black, .3);
  animation-name: showing;
  animation-duration: 150ms;
  animation-timing-function: ease-in-out;

  & + & {
    margin: 16px 0 0;
  }
}

.content {

  display: flex;
  flex: 1 0;
  flex-direction: column;
  padding: 16px 24px;
}

.controls {

  display: flex;
  flex-direction: column;
  flex: 0 0;
  padding: 0 24px 0 24px;
}

.title {

  display: block;
  font-size: 14px;
  font-weight: $font-weight-bold;
  color: $white;
}

.message {

  display: block;
  margin: 8px 0 0;
}

.text {

  display: block;
  font-size: 12px;
  color: $white;
}

.close {

  display: block;
  font-size: 14px;
  color: $white;
  cursor: pointer;
}

.mode {
  &--success {
    background: $green;
  }

  &--danger {
    background: $danger;
  }
}


@keyframes showing {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
